// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig";

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem("menuCollapsed");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  orgId: "",
  create_Org: "",
  all_user_organizations: [],
  organizationLoading: true,
  deleteOp: "",
  userNoti: "",
  notiCount: "",
  sessionAlert: false,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HANDLE_CONTENT_WIDTH":
      return { ...state, contentWidth: action.value };
    case "HANDLE_MENU_COLLAPSED":
      window.localStorage.setItem("menuCollapsed", action.value);
      return { ...state, menuCollapsed: action.value };
    case "HANDLE_MENU_HIDDEN":
      return { ...state, menuHidden: action.value };
    case "HANDLE_RTL":
      return { ...state, isRTL: action.value };
    case "Active_Org":
      localStorage.setItem("orgId", action.value);

      return { ...state, orgId: action.value };
    case "Create_Org":
      return { ...state, create_Org: action.value };
    case "Delete_Operation":
      return { ...state, deleteOp: action.value };
    case "User_Data":
      localStorage.setItem("Users-data", "action.value");
      return { ...state, orgId: action.value };
    case "User_Noti":
      return { ...state, userNoti: action.value };
    case "noti_count":
      return { ...state, notiCount: action.value };
    case "handle_event":
      return { ...state, sessionAlert: action.value };
    case "set_all_user_organizations":
      return {
        ...state,
        all_user_organizations: action.value,
        organizationLoading: false,
      };

    default:
      return state;
  }
};

export default layoutReducer;
