// **  Initial State
const initialState = {
  userData: {},
  ticketPreference: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.payload,
      };
    case "SET_USER_PREFERENCE":
      console.log(action.payload);
      return {
        ...state,
        ticketPreference: action.payload,
      };
    case "LOGOUT":
      const obj = { ...action };
      delete obj.type;
      return { ...state, userData: {}, ...obj };
    default:
      return state;
  }
};

export default authReducer;
