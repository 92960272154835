import { basePathApi } from "./basePathApi";
// let baseUrl = null;
// if ( process.env.NODE_ENV === 'development' )
// baseUrl = 'http://localhost:8000/api/';
// else if ( process.env.NODE_ENV === 'staging' )
// baseUrl = basePathApi+'api/';
// else if ( process.env.NODE_ENV === 'production' )
// baseUrl = basePathApi+'api/';
// export baseUrl
// console.log('REACT_APP_API_URL', process.env.REACT_APP_API_URL)
// console.log('REACT_APP_ENV', process.env.REACT_APP_ENV)
export const baseUrl = basePathApi;
