import { SC } from "../../../views/Helper/ServerCall";
import { get_form_organizations } from "../../../views/Helper/endPoints";

// ** Handles Layout Content Width (full / boxed)
export const handleContentWidth = (value) => (dispatch) =>
  dispatch({ type: "HANDLE_CONTENT_WIDTH", value });

// ** Handles Menu Collapsed State (Bool)
export const handleMenuCollapsed = (value) => (dispatch) =>
  dispatch({ type: "HANDLE_MENU_COLLAPSED", value });

// ** Handles Menu Hidden State (Bool)
export const handleMenuHidden = (value) => (dispatch) =>
  dispatch({ type: "HANDLE_MENU_HIDDEN", value });

// ** Handles RTL (Bool)
export const handleRTL = (value) => (dispatch) =>
  dispatch({ type: "HANDLE_RTL", value });
export const handleOrg = (value) => (dispatch) => {
  return dispatch({ type: "Active_Org", value });
};
export const createOrg = (value) => (dispatch) => {
  return dispatch({ type: "Create_Org", value });
};

export const setAllOrgs = (value) => (dispatch) => {
  return dispatch({ type: "set_all_user_organizations", value });
};
export const hanleDeleteOP = (value) => (dispatch) => {
  console.log("call",value);
  return dispatch({ type: "Delete_Operation", value });
};
export const handleUser = (value) => (dispatch) =>
  dispatch({ type: "User_Data", value });
export const handleUserNotification = (value) => (dispatch) => {
  dispatch({ type: "User_Noti", value });
};
export const handleNotiCount = (value) => (dispatch) => {
  dispatch({ type: "noti_count", value });
};
export const handleSessionEvent = (value) => (dispatch) => {
  dispatch({ type: "handle_event", value });
};

/**
 * SETTING ALL ORGANIZATIONS OF LOGIN USER
 * @param {Array} value
 */
export const setAllUserOrganizations = (value) => (dispatch) => {
  dispatch({ type: "set_all_user_organizations", value });
};
