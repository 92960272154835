// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import { redirect } from "react-router-dom";
import { resetAllTicketsFilterAction } from "../filters";

const config = useJwt.jwtConfig;
/**
 * USER PREFERENCE FOR TICKETS LISTING
 * @param {Object} preferencePayload
 * @returns
 */
export const setUserPreference = (preferencePayload) => (dispatch) => {
  return dispatch({ type: "SET_USER_PREFERENCE", payload: preferencePayload });
};

// ** Handle User Login
export const handleLogin = (data) => {
  localStorage.setItem("userToken", JSON.stringify(data));

  return (dispatch) => {
    dispatch({
      type: "LOGIN",
      payload: data,
      config,
    });
  };
};

// ** Handle User Logout
export const handleLogout = () => {
  return (dispatch) => {
    const scheduledEvent = JSON.parse(localStorage.getItem("scheduledEvent"));
    clearTimeout(scheduledEvent?.timeout);
    localStorage.clear();
    dispatch({
      type: "LOGOUT",
    });

    dispatch(resetAllTicketsFilterAction());
    dispatch({ type: "set_all_user_organizations", value: [] });
    redirect("/login");
  };
};
