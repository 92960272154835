import React, { Component } from "react";
import { Button, Row } from "reactstrap";
import gear from "./../src/assets/images/pages/under.svg";
import "../src/@core/scss/base/custom-rtl.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="app-content content-custom flex-column  overflow-hidden text-center align-items-center ">
          {/* <div className="p-2"> */}
          <h2 className="error-code">Under Maintenance</h2>
          <h2 className="error-code-1">We are sorry, work is in progress.</h2>
          {/* </div> */}
          <div className="d-flex justify-content-center flex-column align-items-center">
            <a href="/">
              <Button.Ripple
                className="under-button  mt-50"
                color="primary"
                type="submit"
              >
                <span style={{ color: "#fff" }}>Back to Dashboard</span>
              </Button.Ripple>
            </a>
            <img
              src={gear}
              alt="loading..."
              className="maintenance-page__img img-fluid "
            />
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
